import * as _ from 'lodash';
import { AppConstants } from '../shared/constants';

export class Utils {
  serviceOutageErrorMessage: any = null;
  APP_CONSTANTS = AppConstants;

  constructor() { }

  /**
   * this is common method use for combining payload
   * @param serviceIdRef this is for service id
   * @param payloadVarPassed this is for payload
   */
  constructPayload(serviceIdRef: any, payloadVarPassed?: any) {
    const payload = {};
    payload['SERVICE_ID'] = serviceIdRef;
    if (payloadVarPassed) {
      _.assign(payload, payloadVarPassed);
    }
    return payload;
  }

  // Handling response errors for the service integration.
  checkServiceStatus() {
    this.serviceOutageErrorMessage = this.APP_CONSTANTS.STATIC.EXPERIENCING_TECHNICAL_DIFFICULTIES;
  }

}
