import { HttpClient } from "@angular/common/http";
import { Injectable, isDevMode } from "@angular/core";
import { RestClientService, WindowRefService } from "@ng/lfg-common-utilities";
import { LfgLoaderService } from "@ng/lfg-loader";
import { environment } from "src/environments/environment";
import { AppConstants } from "./constants";
import { Utils } from "./utils";
import { Observable } from "rxjs";

@Injectable()
export class DataService extends RestClientService {
  payload: any;
  APP_CONSTANTS = AppConstants;
  utils: any;
  private limit = 60000;
  resource: any;

  constructor(
    http: HttpClient,
    private windowRefService: WindowRefService,
    private loaderService: LfgLoaderService,
  ) {
    super(http);
    this.utils = new Utils();
  }

  /** Service call to get guid */
  getDocuSignUrl(payLoad?: any, resource?: any, responseType?: any): Observable<any> {
    const data = {
      env: environment.auth0EnvName
    };
    if (isDevMode()) {
      return this.get('/assets/mock/docusign-url.json');
      // return this.get('/assets/mock/docusign-url-failed.json');
      // return this.post(this.getApiUrl(data.env) + resource, payLoad);
    } else {
      return this.makeCall<any>(this.getApiUrl(data.env) + resource, payLoad, responseType, this.getHttpHeaders(data.env));
    }
  }

  getApiUrl(env: string): string {
    if (env === 'local' || env === 'test' || env === 'uat' || env === 'preprod') {
      env = 'dev';
    }
    try {
      return environment.serviceUrls[env];
    } catch (e) {
      console.error('Unable to retrive URL. ' + e);
      throw new Error('Unable to retrieve URL');
    }
  }

  getHttpHeaders(env: string): any {
    const headers = {
      env: env
    };
    return headers;
  }

}
