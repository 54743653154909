const host = `${window.location.hostname}`;
let env = 'local';
let auth0env = 'local';

if (host === 'lincolnfinancial.com' || host === 'www.lincolnfinancial.com') {
  env = 'prod';
  auth0env = 'prod';
} else if (host === 'lincolnfinancial-preprod.lfg.com') {
  env = 'preprod';
  auth0env = 'preprod';
} else if (host === 'lincolnfinancial-uat.lfg.com') {
  env = 'uat';
  auth0env = 'uat';
} else if (host === 'lincolnfinancial-dev.lfg.com') {
  env = 'dev';
  auth0env = 'dev';
} else if (host === 'lincolnfinancial-test.lfg.com') {
  env = 'test';
  auth0env = 'test';
} else if (host === 'w4resignui-dev.web.lfg.com') {
  env = 'test';
  auth0env = 'awsdev';
} else if (host === 'w4resignui.web.lfg.com') {
  env = 'prod';
  auth0env = 'awsprod';
}

export const environment = {
  production: true,
  appVersion: '1.0.0',
  envName: env,
  auth0EnvName: auth0env,
  auth0: {
    local: {
      // change the client id/domain/audience to diff. env. in local
      clientId: 'vVGBYQEjVclX8kQNtDgTQkBlYiBrSF8h',
      domain: 'https://auth.lincolnfinancial-test.lfg.com',
      audience: 'https://lincolnfinancial-test.lfg.com/api',
      redirectUri: 'http://localhost:4200'
    },
    dev: {
      clientId: 'l0TDCWwueeCKP3NXUZkkxTOYN7XDuK4z',
      domain: 'https://auth.lincolnfinancial-dev.lfg.com',
      audience: 'https://lincolnfinancial-dev.lfg.com/api',
      redirectUri: 'https://lincolnfinancial-dev.lfg.com/secure/consumer/w4r-esign'
    },
    test: {
      clientId: 'vVGBYQEjVclX8kQNtDgTQkBlYiBrSF8h',
      domain: 'https://auth.lincolnfinancial-test.lfg.com',
      audience: 'https://lincolnfinancial-test.lfg.com/api',
      redirectUri: 'https://lincolnfinancial-test.lfg.com/secure/consumer/w4r-esign'
    },
    uat: {
      clientId: 'amHLpXAnQHZcURttyXYPAei1MnxRDqsp',
      domain: 'https://auth.lincolnfinancial-uat.lfg.com',
      audience: 'https://lincolnfinancial-uat.lfg.com/api',
      redirectUri: 'https://lincolnfinancial-uat.lfg.com/secure/consumer/w4r-esign'
    },
    preprod: {
      clientId: 'T57L7F8RdazyDMm6UIiMP5kJjwstGd2z',
      domain: 'https://auth.lincolnfinancial-preprod.lfg.com',
      audience: 'https://lincolnfinancial-preprod.lfg.com/api',
      redirectUri: 'https://lincolnfinancial-preprod.lfg.com/secure/consumer/w4r-esign'
    },
    prod: {
      clientId: 'YgCbhBq1czqnI2NgYgQyXDlqw1UFYIir',
      domain: 'https://auth.lincolnfinancial.com',
      audience: 'https://www.lincolnfinancial.com/api',
      redirectUri: 'https://www.lincolnfinancial.com/secure/consumer/w4r-esign'
    },
    awsdev: {
      clientId: 'vVGBYQEjVclX8kQNtDgTQkBlYiBrSF8h',
      domain: 'https://auth.lincolnfinancial-test.lfg.com',
      audience: 'https://lincolnfinancial-test.lfg.com/api',
      redirectUri: 'https://w4resignui-dev.web.lfg.com'
    },
    awsprod: {
      clientId: 'YgCbhBq1czqnI2NgYgQyXDlqw1UFYIir',
      domain: 'https://auth.lincolnfinancial.com',
      audience: 'https://www.lincolnfinancial.com/api',
      redirectUri: 'https://w4resignui.web.lfg.com'
    }
  },
  serviceUrls: {
    local: 'https://w4resignapi-dev.web.LFG.com/api/',
    dev: 'https://w4resignapi-dev.web.LFG.com/api/',
    prod: 'https://w4resignapi.web.LFG.com/api/'
  },
};
