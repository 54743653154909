export const AppConstants = {
  PAYLOAD: {
    GET_USER_PROFILE_SERVICE_ID: 'INT_UPS_GET_USER_PROFILE',
    GET_USER_PROFILE_DEV_SERVICE_ID: 'getUserProfile',
    GET_DOCUSIGN_URL_SERVICE_ID: 'INT_START',
    GET_DOCUSIGN_URL_DEV_SERVICE_ID: 'start',
  },
  STATIC: {
    EXPERIENCING_TECHNICAL_DIFFICULTIES:
      'We are currently experiencing technical difficulties. Please try again later.'
  }
}


