import { Component, OnInit } from '@angular/core';
import { WindowRefService } from '@ng/lfg-common-utilities';
import { LfgLoaderService } from '@ng/lfg-loader';

@Component({
  selector: 'app-close',
  templateUrl: './close.component.html',
  styleUrls: ['./close.component.scss']
})
export class CloseComponent implements OnInit {

  constructor(private winRefService: WindowRefService, private lfgLoaderService: LfgLoaderService) { }

  ngOnInit(): void {
    this.lfgLoaderService.show();
    setTimeout(() => {
      this.winRefService.getWindow().close();
      this.lfgLoaderService.hide();
    }, 2000);
  }

}
