import { APP_BASE_HREF } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { AuthHttpInterceptor, AuthModule } from '@auth0/auth0-angular';
import { RestClientService, WindowRefService } from '@ng/lfg-common-utilities';
import { environment } from 'src/environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LandingComponent } from './landing/landing.component';
import { DataService } from './shared/data-service';
import { CloseComponent } from './close/close.component';
import { LfgLoaderModule } from '@ng/lfg-loader';
import { LfgFormWidgetsModule } from '@ng/lfg-form-widgets';


@NgModule({
  declarations: [
    AppComponent,
    LandingComponent,
    CloseComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    LfgLoaderModule,
    LfgFormWidgetsModule,
    AuthModule.forRoot({
      domain: environment.auth0[environment.auth0EnvName]['domain'],
      clientId: environment.auth0[environment.auth0EnvName]['clientId'],
      audience: environment.auth0[environment.auth0EnvName]['audience'],
      redirectUri: environment.auth0[environment.auth0EnvName]['redirectUri'],
      useRefreshTokens: true,
      httpInterceptor: {
        allowedList: [
          {
            uri: '*',
            tokenOptions: {
              audience: environment.auth0[environment.auth0EnvName]['audience']
            },
          }
        ],
      },
    })
  ],
  providers: [DataService,
    RestClientService,
    WindowRefService,
    { provide: APP_BASE_HREF, useValue: '/' },
    // Comment below line to disable auth0 for local development. DO NOT CHECK IN
    { provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
