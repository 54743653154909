import { Component, OnInit, SecurityContext } from '@angular/core';
import { LfgLoaderService } from '@ng/lfg-loader';
import { AppConstants } from '../shared/constants';
import { Utils } from '../shared/utils';
import { DataService } from '../shared/data-service';
import { WindowRefService } from '@ng/lfg-common-utilities';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss'],
})
export class LandingComponent implements OnInit {

  APP_CONSTANTS = AppConstants;
  utils: Utils;
  constructor(private loaderService: LfgLoaderService, private clientService: DataService, private winRefService: WindowRefService,
    protected sanitizer: DomSanitizer) {
    this.utils = new Utils();
  }

  ngOnInit(): void {
    this.getDocusignUrl();
  }

  getDocusignUrl() {
    this.loaderService.show();
    const resourceType = this.APP_CONSTANTS.PAYLOAD.GET_DOCUSIGN_URL_DEV_SERVICE_ID;
    const guid = sessionStorage.getItem('guid');
    const payload = this.utils.constructPayload(
      this.APP_CONSTANTS.PAYLOAD.GET_DOCUSIGN_URL_SERVICE_ID,
      { 'requestId': guid }
    );
    this.clientService.getDocuSignUrl(payload, resourceType)
      .subscribe({
        next: (res) => {
          if (res.responseStatus === 'SUCCESS') {
            if (res.data && res.data.url) {
              let docusignUrl:string;
              docusignUrl = this.sanitizer.sanitize(SecurityContext.URL, res.data.url)!;
              this.winRefService.getWindow().open(docusignUrl, '_self');
            }
          } else if (res.responseStatus === 'FAILURE') {
            this.utils.checkServiceStatus();
            console.log(res.error);
            this.winRefService.getWindow().close();
          }
        },
        error: (e) => {
          this.utils.checkServiceStatus();
          console.log(e);
          this.winRefService.getWindow().close();
        },
        complete: () => {
          this.loaderService.hide();
        }
      });
  }
}
